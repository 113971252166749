// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require("local-time").start()

window.Rails = Rails

import 'bootstrap'
import 'data-confirm-modal'
import hyperform from 'hyperform'
import Cleave from 'cleave.js';
require('cleave.js/dist/addons/cleave-phone.ch');

$(document).on("turbolinks:load", () => {
  $('[data-toggle="tooltip"]').tooltip()
  $('[data-toggle="popover"]').popover()

  hyperform.addTranslation("de",{
    TextTooLong:"Bitte kürzen Sie diesen Text auf maximal %l Zeichen (Sie verwenden derzeit %l Zeichen).",
    TextTooShort:"Bitte verwenden Sie zumindest %l Zeichen (Sie verwenden derzeit %l Zeichen).",
    ValueMissing:"Bitte füllen Sie dieses Feld aus.",
    CheckboxMissing:"Bitte klicken Sie dieses Kästchen an, wenn Sie fortsetzen wollen.",
    RadioMissing:"Bitte wählen Sie eine dieser Optionen.",
    FileMissing:"Bitte wählen Sie eine Datei.",
    SelectMissing:"Bitte wählen Sie einen Eintrag in der Liste.",
    InvalidEmail:"Bitte geben Sie eine E-Mail-Adresse ein.",
    InvalidURL:"Bitte geben Sie eine Internetadresse ein.",
    PatternMismatch:"Bitte halten Sie sich an das vorgegebene Format.",
    PatternMismatchWithTitle:"Bitte halten Sie sich an das vorgegebene Format: %l.",
    NumberRangeOverflow:"Bitte wählen Sie einen Wert, der nicht größer ist als %l.",
    DateRangeOverflow:"Bitte wählen Sie einen Wert, der nicht später ist als %l.",
    TimeRangeOverflow:"Bitte wählen Sie einen Wert, der nicht später ist als %l.",
    NumberRangeUnderflow:"Bitte wählen Sie einen Wert, der nicht kleiner ist als %l.",
    DateRangeUnderflow:"Bitte wählen Sie einen Wert, der nicht früher ist als %l.",
    TimeRangeUnderflow:"Bitte wählen Sie einen Wert, der nicht früher ist als %l.",
    StepMismatch:"Bitte wählen Sie einen gültigen Wert. Die zwei nähesten gültigen Werte sind %l und %l.",
    StepMismatchOneValue:"Bitte wählen Sie einen gültigen Wert. Der näheste gültige Wert ist %l.",
    BadInputNumber:"Bitte geben Sie eine Nummer ein.",
    "Please match the requested type.": "Bitte passen Sie die Eingabe dem geforderten Typ an.",
    "Please comply with all requirements.": "Bitte erfüllen Sie alle Anforderungen.",
    "Please lengthen this text to %l characters or more (you are currently using %l characters).": "Bitte verlängern Sie diesen Text auf mindestens %l Zeichen (Sie verwenden derzeit %l Zeichen).",
    "Please use the appropriate format.": "Bitte verwenden Sie das passende Format.",
    "Please enter a comma separated list of email addresses.": "Bitte geben Sie eine komma-getrennte Liste von E-Mail-Adressen an.",
    "Please select a file of the correct type.": "Bitte wählen Sie eine Datei vom korrekten Typ.",
    "Please select one or more files.": "Bitte wählen Sie eine oder mehrere Dateien.",
    "any value":"jeder Wert",
    "any valid value":"jeder gültige Wert",
  });
  hyperform.setLanguage("de");
  hyperform(window)

  if ($('.js-phone-number').length > 0) {
    new Cleave($('.js-phone-number')[0], {
      phone: true,
      phoneRegionCode: 'ch'
    });
  }

  let index  = 0,
      $top   = $('#js-cycle-bg');

  let images = $top.data('images')

  setInterval(function() {
    $top.animate({ opacity: 0 }, 500, function() {
      if(index === images.length-1) index = -1;
      $top.css('background-image', 'url('+images[++index]+')');
      $top.animate({ opacity: 1 }, 500);
    });
  }, 3000);
})
